<template>
    <div>
        <b-form v-on:change="onFormChange">
            <b-form-group label="Location Mode:">
                <b-form-radio v-model="form.useAddr" name="addr-radios" value="0">Use Address</b-form-radio>
                <b-form-radio v-model="form.useAddr" name="addr-radios" value="1">Use GPS</b-form-radio>
            </b-form-group>
            <b-form-group
                id="sys-street-group"
                label="Street:"
                label-for="sys-street"
                v-if="form.useAddr === '0'"
            >
                <b-form-input
                    id="sys-street"
                    type="text"
                    v-model="form.street"
                    :formatter="addrFormatter"
                    placeholder="Enter Street Address"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="sys-city-group"
                label="City:"
                label-for="sys-city"
                v-if="form.useAddr === '0'"
            >
                <b-form-input
                    id="sys-city"
                    type="text"
                    v-model="form.city"
                    placeholder="Enter City"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="sys-state-group"
                label="State:"
                label-for="sys-state"
                v-if="form.useAddr === '0'"
            >
                <b-form-select
                    id="sys-state"
                    v-model="form.state"
                    :options="stateOptions"
                ></b-form-select>
            </b-form-group>
            <b-form-group
                id="sys-zip-group"
                label="Zip Code:"
                label-for="sys-zip"
                v-if="form.useAddr === '0'"
            >
                <b-form-input
                    id="sys-zip"
                    type="text"
                    v-model="form.zipcode"
                    placeholder="Enter Zip Code"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="sys-lat-group"
                label="Latitude:"
                label-for="sys-lat"
                v-if="form.useAddr === '1'"
            >
                <b-form-input
                    id="sys-lat"
                    type="number"
                    v-model="form.lat"
                    placeholder="Enter Latitude"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="sys-lon-group"
                label="Longitude:"
                label-for="sys-lon"
                v-if="form.useAddr === '1'"
            >
                <b-form-input
                    id="sys-lon"
                    type="number"
                    v-model="form.lon"
                    placeholder="Enter Longitude"
                ></b-form-input>
            </b-form-group>
            <div class="text-center" v-if="form.useAddr === '0'">
                <b-button variant="success" @click="verifyAddr">Verify Address</b-button>
            </div>
        </b-form>
        <b-modal
            ref="loc-verify-modal"
            hide-footer
            centered
            title="Verify Address"
        >
            <b-table
                id="results"
                striped hover
                selectable
                @row-selected="onRowSelected"
                select-mode="single"
                :items="locSearchResults"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                style="overflow-y: hidden"
            >
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="results"
                align="fill"
                size="sm"
            ></b-pagination>
        </b-modal>
    </div>
</template>

<script>
import stateList from "@/state-list";
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "DeviceLocation",
    data() {
        return {
            form: {
                useAddr: "0",
                street: '',
                city: '',
                state: '',
                zipcode: null,
                lat: null,
                lon: null
            },
            fields: ['street', 'city', 'state', 'zipcode'],
            stateOptions: null,
            locSearchResults: [],
            perPage: 5,
            currentPage: 1
        }
    },
    mounted() {
        EventBus.$on("UpdateDeviceLoc", () => {
            const geo = this.$store.getters.adminSelectedGeo;
            this.form.lat = geo.lat;
            this.form.lon = geo.lon;
            this.form.street = geo.street;
            this.form.city = geo.city;
            this.form.state = geo.state;
            this.form.zipcode = geo.zipcode;
            this.$store.commit('storeDeviceGeo', this.form);
        });
    },
    beforeDestroy() {
        EventBus.$off("UpdateDeviceLoc");
    },
    created() {
        this.stateOptions = stateList.stateList;
    },
    methods: {
        onRowSelected(items) {
            //console.log(items);
            this.form.street = items[0].street;
            this.form.city = items[0].city;
            for (let i = 0; i < this.stateOptions.length; i++) {
                if (this.stateOptions[i].text === items[0].state) {
                    this.form.state = this.stateOptions[i].value;
                }
            }
            this.form.zipcode = items[0].zipcode;
            this.$refs['loc-verify-modal'].hide();
        },
        onFormChange() {
            this.$store.commit('storeDeviceGeo', this.form);
        },
        addrFormatter(value) {
          return value.substr(0, 48);
        },
        verifyAddr() {
            //console.log("Checking Address");
            this.locSearchResults = [];
            this.$store.dispatch('getGeoLocResults', this.form).then(result => {
                //console.log(result);
                if (result.loc.length > 0) {
                    this.locSearchResults = result.loc;
                    this.$refs['loc-verify-modal'].show();
                }
                else {
                    this.$bvToast.toast('No Results Found, Trying adding more info', {
                        title: 'Verify Address',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    },
    computed: {
        rows() {
            return this.locSearchResults.length
        }
    }
}
</script>

<style scoped>

</style>
