<template>
  <div>
    <b-button-toolbar>
      <b-button-group class="mx-1">
        <b-dropdown text="Options" v-if="isAllowed">
            <b-dropdown-item @click="restoreProvision">Restore - Re-Provision</b-dropdown-item>
            <b-dropdown-item @click="restoreData">Restore - Data Only</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="editSysConfig">Edit System Config</b-dropdown-item>
            <b-dropdown-item @click="editLocation">Edit Location</b-dropdown-item>
            <b-dropdown-item @click="editUsers">Edit Device Users</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="dashboard">Device Dashboard</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="editSpraySched">Edit Spray Schedule</b-dropdown-item>
            <b-dropdown-item @click="editSusSched">Edit Suspend Schedule</b-dropdown-item>
            <b-dropdown-item @click="viewActHistory">View Activity History</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="debug">Debug</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="fwManager">FW Manager</b-dropdown-item>
        </b-dropdown>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>

<script>
import roles from '@/roles';
export default {
  name: "AdminDeviceMenu",
  data() {
    return {

    }
  },
    methods: {
        restoreProvision() {
            this.$store.dispatch('restoreDeviceSettings', {
                deviceId: this.$store.getters.adminSelectedDev.deviceId,
                mode: 0
            }).then(() => {
                this.$bvToast.toast('Restore / Re-Sync Set For Device', {
                    title: 'Restore Device',
                    variant: 'success',
                    solid: true
                });
            }).catch(() => {
                this.$bvToast.toast('Error Setting Restore / Re-Sync', {
                    title: 'Restore Device',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        restoreData() {
            this.$store.dispatch('restoreDeviceSettings', {
                deviceId: this.$store.getters.adminSelectedDev.deviceId,
                mode: 1
            }).then(() => {
                this.$bvToast.toast('Restore / Re-Sync Set For Device', {
                    title: 'Restore Device',
                    variant: 'success',
                    solid: true
                });
            }).catch(() => {
                this.$bvToast.toast('Error Setting Restore / Re-Sync', {
                    title: 'Restore Device',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        editSysConfig() {
            this.$router.push('/admin/editor/config/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        editLocation() {
            this.$router.push('/admin/editor/location/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        editUsers() {
            this.$router.push('/admin/editor/devusers/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        dashboard() {
            this.$router.push('/admin/dev/dashboard/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        editSpraySched() {
            if (this.$store.getters.adminSelectedDev.deviceType === "InsectControl")
                this.$router.push('/admin/editor/schedule/' + this.$store.getters.adminSelectedDev.deviceId);
            else if (this.$store.getters.adminSelectedDev.deviceType === "CoolMist")
                this.$router.push('/admin/editor/schedule/coolmist/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        editSusSched() {
            this.$router.push('/admin/editor/suspend/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        viewActHistory() {
            if (this.$store.getters.adminSelectedDev.deviceType === "InsectControl")
                this.$router.push('/admin/view/activity/' + this.$store.getters.adminSelectedDev.deviceId);
            else if (this.$store.getters.adminSelectedDev.deviceType === "CoolMist")
                this.$router.push('/admin/view/activity/coolmist/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        debug() {
            this.$router.push('/admin/dev/debug/' + this.$store.getters.adminSelectedDev.deviceId);
        },
        fwManager() {
            this.$router.push('/admin/fw/manager');
        }
    },
  computed: {
    isAllowed() {
      if (this.$store.getters.role >= roles.roles.admin)
        return true;
      else
        return false;
    },
    isSuperAllowed() {
      if (this.$store.getters.role >= roles.roles.superUser)
        return true;
      else
        return false;
    }
  }
}
</script>

<style scoped>

</style>
