<template>
<div>
    <b-container>
        <b-row
            cols="5"
            align-content="between"
        >
            <b-col cols="12" md="9">
                <h3>Edit Device Location</h3>
            </b-col>
            <b-col cols="12" md="3">
                <admin-device-menu></admin-device-menu>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>{{devCustomer}} - {{devId}}</p>
            </b-col>
        </b-row>
    </b-container>
    <hr>
    <div class="text-center">
        <b-card class="mb-1 ml-2 mr-1">
            <device-location></device-location>
        </b-card>
    </div>
    <hr>
    <div class="text-center">
        <b-button @click="saveLocation" variant="outline-primary">
            <b-icon icon="cloud-arrow-up"></b-icon> Save
        </b-button>
    </div>
</div>
</template>

<script>
import DeviceLocation from "@/components/AdminModules/Devices/DeviceLocation";
import { EventBus } from '@/plugins/event-bus';
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
    name: "AdminEditLocation",
    props: ['id'],
    data() {
        return {
            devId: null,
            userId: null
        }
    },
    components: {
        DeviceLocation,
        AdminDeviceMenu
    },
    created() {
        this.devId = this.$route.params.id;
        this.userId = this.devIdToUserId(this.devId);
        this.$store.dispatch('getAdminDeviceGeo', {
            id: this.devId,
            userId: this.userId
        }).then(() => {
            EventBus.$emit("UpdateDeviceLoc");
        }).catch(() => {
            console.log("Error Getting Device Sys Settings");
        });
    },
    mounted() {

    },
    methods: {
        saveLocation() {
            const geoData = this.$store.getters.deviceGeo;
            const devGeo = {
                id: this.devId,
                userId: this.userId,
                ip: '',
                useAddr: geoData.useAddr,
                lat: geoData.lat,
                lon: geoData.lon,
                street: geoData.street,
                city: geoData.city,
                state: geoData.state,
                zipcode: geoData.zipcode
            };
            if (devGeo.useAddr === "0") {
                this.$store.dispatch('verifyDeviceAddr', devGeo).then(() => {
                    this.$store.dispatch('setDeviceGeo', devGeo).then(() => {
                        this.$bvToast.toast('Location Saved', {
                            title: 'Save Location',
                            variant: 'success',
                            solid: true
                        });
                        setTimeout(() => {
                            this.$router.back();
                        }, 1000);
                    }).catch(() => {
                        this.$bvToast.toast('Error Saving Location', {
                            title: 'Save Location',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }).catch(() => {
                    this.$bvToast.toast('Invalid Address', {
                        title: 'Save Location',
                        variant: 'danger',
                        solid: true
                    });
                });
            }
            else {
                this.$store.dispatch('setDeviceGeoGps', devGeo).then(() => {
                    this.$bvToast.toast('Location Saved', {
                        title: 'Save Location',
                        variant: 'success',
                        solid: true
                    });
                    setTimeout(() => {
                        this.$router.back();
                    }, 1000);
                }).catch(() => {
                    this.$bvToast.toast('Error Saving Location', {
                        title: 'Save Location',
                        variant: 'danger',
                        solid: true
                    });
                });
            }
        },
        devIdToUserId(value) {
            if (this.$store.getters.adminDevList.length !== 0) {
                const devList = this.$store.getters.adminDevList;
                for (let i = 0; i < devList.length; i++) {
                    if (devList[i].deviceId === value)
                        return devList[i].userId;
                }
            }
            return 'None';
        }
    },
    computed: {
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
                return "";
            else
                return this.$store.getters.deviceSys.sysName;
        }
    }
}
</script>

<style scoped>

</style>
